// extracted by mini-css-extract-plugin
export var caption = "by_fS";
export var grey = "by_c6";
export var heading = "by_G";
export var image = "by_d";
export var left = "by_bD";
export var reverse = "by_c3";
export var right = "by_bF";
export var section = "by_b";
export var video = "by_fT";
export var videoContainer = "by_cd";
export var videoWrapper = "by_cf";