// extracted by mini-css-extract-plugin
export var container = "Q_h";
export var content = "Q_bV";
export var icon = "Q_br";
export var iconContainer = "Q_bM";
export var icons = "Q_bQ";
export var image = "Q_d";
export var label = "Q_cp";
export var section = "Q_b";
export var text = "Q_ch";
export var title = "Q_f";