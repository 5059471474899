// extracted by mini-css-extract-plugin
export var appStores = "W_c5";
export var grey = "W_c6";
export var icon = "W_br";
export var iconGroup = "W_c4";
export var image = "W_d";
export var left = "W_bD";
export var reverse = "W_c3";
export var right = "W_bF";
export var section = "W_b";
export var textSection = "W_c2";