// extracted by mini-css-extract-plugin
export var buttons = "bb_cl";
export var container = "bb_h";
export var content = "bb_bV";
export var icon = "bb_br";
export var iconContainer = "bb_bM";
export var image = "bb_d";
export var section = "bb_b";
export var selected = "bb_bb";
export var tab = "bb_dm";
export var tabText = "bb_dl";