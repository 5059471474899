// extracted by mini-css-extract-plugin
export var bottomWave = "bw_cM";
export var button = "bw_b5";
export var buttonContainer = "bw_cr";
export var buttonFooter = "bw_fw";
export var container = "bw_h";
export var middleWave = "bw_cL";
export var section = "bw_b";
export var subtitle = "bw_cm";
export var textContainer = "bw_bK";
export var title = "bw_f";