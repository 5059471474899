// extracted by mini-css-extract-plugin
export var background = "bt_z";
export var backgroundContainer = "bt_fr";
export var bottomImage = "bt_t";
export var bottomImageContainer = "bt_fv";
export var buttons = "bt_cl";
export var container = "bt_h";
export var imagesContainer = "bt_ft";
export var middleWave = "bt_cL";
export var section = "bt_b";
export var text = "bt_ch";
export var textContainer = "bt_bK";
export var title = "bt_f";
export var topImage = "bt_m";
export var waveOverlay = "bt_fs";