// extracted by mini-css-extract-plugin
export var background = "bK_z";
export var backgroundContainer = "bK_fr";
export var bottomWave = "bK_cM";
export var buttons = "bK_cl";
export var container = "bK_h";
export var content = "bK_bV";
export var icon = "bK_br";
export var iconContainer = "bK_bM";
export var middleWave = "bK_cL";
export var negativeTop = "bK_g0";
export var overlap = "bK_g2";
export var section = "bK_b";
export var selected = "bK_bb";
export var tab = "bK_dm";
export var tabText = "bK_dl";
export var title = "bK_f";
export var topWave = "bK_g1";