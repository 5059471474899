// extracted by mini-css-extract-plugin
export var active = "bz_8";
export var categories = "bz_cP";
export var category = "bz_cQ";
export var icon = "bz_br";
export var icons = "bz_bQ";
export var image = "bz_d";
export var member = "bz_fW";
export var memberTitle = "bz_fX";
export var name = "bz_fn";
export var placeholder = "bz_6";
export var section = "bz_b";
export var show = "bz_bj";
export var subtitle = "bz_cm";
export var team = "bz_fV";