// extracted by mini-css-extract-plugin
export var bottomCircle = "bC_gd";
export var content = "bC_bV";
export var description = "bC_dn";
export var hide = "bC_C";
export var icon = "bC_br";
export var image = "bC_d";
export var imageContainer = "bC_gj";
export var left = "bC_bD";
export var nextYear = "bC_gh";
export var right = "bC_bF";
export var section = "bC_b";
export var shrink = "bC_gg";
export var title = "bC_f";
export var topCircle = "bC_gc";
export var wave = "bC_fY";
export var waveContainer = "bC_dP";
export var year = "bC_gf";