// extracted by mini-css-extract-plugin
export var buttons = "X_cl";
export var column = "X_bX";
export var columns = "X_bW";
export var container = "X_h";
export var down = "X_dc";
export var download = "X_db";
export var footer = "X_cG";
export var information = "X_c9";
export var section = "X_b";
export var show = "X_bj";
export var text = "X_ch";
export var title = "X_f";
export var units = "X_dd";
export var up = "X_dg";
export var upload = "X_df";
export var waveOne = "X_q";
export var waveThree = "X_c8";
export var waveTwo = "X_n";
export var waves = "X_c7";