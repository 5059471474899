// extracted by mini-css-extract-plugin
export var buttons = "bq_cl";
export var container = "bq_h";
export var image = "bq_d";
export var innerText = "bq_fl";
export var logo = "bq_bk";
export var p = "bq_d9";
export var section = "bq_b";
export var short = "bq_fb";
export var tall = "bq_fm";
export var textContainer = "bq_bK";