// extracted by mini-css-extract-plugin
export var container = "bJ_h";
export var feature = "bJ_gY";
export var featureText = "bJ_gZ";
export var features = "bJ_gX";
export var footer = "bJ_cG";
export var footerText = "bJ_cH";
export var icon = "bJ_br";
export var section = "bJ_b";
export var text = "bJ_ch";
export var title = "bJ_f";
export var waveOne = "bJ_q";
export var waveThree = "bJ_c8";
export var waveTwo = "bJ_n";
export var waves = "bJ_c7";