// extracted by mini-css-extract-plugin
export var arrow = "bh_5";
export var body = "bh_g";
export var circle = "bh_d0";
export var customer = "bh_dZ";
export var feedback = "bh_dY";
export var feedbacks = "bh_dW";
export var footer = "bh_cG";
export var footerText = "bh_cH";
export var left = "bh_bD";
export var nameLocation = "bh_d1";
export var padding = "bh_dD";
export var right = "bh_bF";
export var section = "bh_b";
export var selected = "bh_bb";
export var selectors = "bh_dV";
export var subtitle = "bh_cm";
export var text = "bh_ch";
export var title = "bh_f";
export var wrapper = "bh_dX";