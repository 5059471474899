// extracted by mini-css-extract-plugin
export var button = "bg_b5";
export var categories = "bg_cP";
export var footerText = "bg_cH";
export var input = "bg_cF";
export var map = "bg_c1";
export var mapContainer = "bg_c0";
export var offered = "bg_cY";
export var search = "bg_cD";
export var section = "bg_b";
export var title = "bg_f";