// extracted by mini-css-extract-plugin
export var body = "bL_g";
export var button = "bL_b5";
export var close = "bL_F";
export var container = "bL_h";
export var content = "bL_bV";
export var heading = "bL_G";
export var link = "bL_bq";
export var mobileHide = "bL_g3";
export var open = "bL_9";
export var section = "bL_b";
export var show = "bL_bj";
export var title = "bL_f";