// extracted by mini-css-extract-plugin
export var answer = "bG_gT";
export var arrow = "bG_5";
export var body = "bG_g";
export var category = "bG_cQ";
export var contactIcons = "bG_gM";
export var current = "bG_fj";
export var fade = "bG_gQ";
export var footer = "bG_cG";
export var footerText = "bG_cH";
export var icon = "bG_br";
export var iconContainer = "bG_bM";
export var issue = "bG_gS";
export var issueContainer = "bG_gP";
export var issueText = "bG_gR";
export var issues = "bG_gN";
export var left = "bG_bD";
export var padding = "bG_dD";
export var right = "bG_bF";
export var section = "bG_b";
export var selectors = "bG_dV";
export var subtitle = "bG_cm";
export var title = "bG_f";