// extracted by mini-css-extract-plugin
export var buttons = "bF_cl";
export var left = "bF_bD";
export var media = "bF_gL";
export var right = "bF_bF";
export var section = "bF_b";
export var selected = "bF_bb";
export var signature = "bF_gK";
export var testimonial = "bF_gJ";
export var text = "bF_ch";
export var textContainer = "bF_bK";
export var video = "bF_fT";