// extracted by mini-css-extract-plugin
export var body = "br_g";
export var buttons = "br_cl";
export var container = "br_h";
export var icon = "br_br";
export var icons = "br_bQ";
export var image = "br_d";
export var items = "br_fp";
export var label = "br_cp";
export var mobileButtons = "br_fq";
export var name = "br_fn";
export var section = "br_b";
export var selected = "br_bb";
export var show = "br_bj";
export var title = "br_f";