// extracted by mini-css-extract-plugin
export var bottom = "bB_s";
export var contact = "bB_fZ";
export var faqCat = "bB_f6";
export var faqCatDescription = "bB_f9";
export var faqCatTitle = "bB_f8";
export var faqCategories = "bB_f5";
export var faqIcon = "bB_f7";
export var footerText = "bB_cH";
export var icon = "bB_br";
export var iconContainer = "bB_bM";
export var iconText = "bB_f1";
export var immediate = "bB_f0";
export var inputContainer = "bB_cR";
export var linksSubtitle = "bB_f3";
export var linksTitle = "bB_f2";
export var search = "bB_cD";
export var searchInput = "bB_cS";
export var section = "bB_b";
export var selected = "bB_bb";
export var supportButton = "bB_gb";
export var supportLinks = "bB_f4";
export var top = "bB_l";
export var wave = "bB_fY";