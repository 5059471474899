// extracted by mini-css-extract-plugin
export var bottomContainer = "L_ck";
export var buttons = "L_cl";
export var icon = "L_br";
export var link = "L_bq";
export var section = "L_b";
export var social = "L_cj";
export var text = "L_ch";
export var title = "L_f";
export var topContainer = "L_cg";
export var videoContainer = "L_cd";
export var videoWrapper = "L_cf";